import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { ILumiaApi } from './ILumiaApi';
import {
  CreateLumiaThreadRequest,
  CreateLumiaThreadResponse,
  DeleteLumiaThreadRequest,
  DeleteLumiaThreadResponse,
  GetLumiaThreadImageLinkRequest,
  GetLumiaThreadImageLinkResponse,
  ListLumiaThreadMessagesRequest,
  ListLumiaThreadMessagesResponse,
  ListLumiaThreadsRequest,
  ListLumiaThreadsResponse,
} from './lumia.models';

export class LumiaApi implements ILumiaApi {
  constructor(private httpClient: IHttpClient) {}

  public readonly createThread = async ({
    companyId,
    teamId,
    body,
  }: CreateLumiaThreadRequest) => {
    const res = await this.httpClient.post<CreateLumiaThreadResponse>(
      `/harmonia-fh/company/${companyId}/thread`,
      {
        isAuthorized: true,
        body,
        params: teamId ? { teamId } : undefined,
      }
    );

    return res.item;
  };

  public readonly deleteThread = async ({
    companyId,
    threadId,
    teamId,
  }: DeleteLumiaThreadRequest) => {
    const res = await this.httpClient.delete<DeleteLumiaThreadResponse>(
      `/harmonia-fh/company/${companyId}/thread/${threadId}`,
      {
        isAuthorized: true,
        params: teamId ? { teamId } : undefined,
      }
    );

    return res;
  };

  public readonly listThreads = async ({
    companyId,
    teamId,
  }: ListLumiaThreadsRequest) => {
    const res = await this.httpClient.get<ListLumiaThreadsResponse>(
      `/harmonia-fh/company/${companyId}/thread/`,
      {
        isAuthorized: true,
        params: teamId ? { teamId } : undefined,
      }
    );

    return res.items;
  };

  public readonly listThreadMessages = async ({
    companyId,
    threadId,
    teamId,
  }: ListLumiaThreadMessagesRequest) => {
    const res = await this.httpClient.get<ListLumiaThreadMessagesResponse>(
      `/harmonia-fh/company/${companyId}/thread/${threadId}/messages`,
      { 
        isAuthorized: true,
        params: teamId ? { teamId } : undefined,
      }
    );

    return res.items;
  };

  public readonly getThreadImageLink = async ({
    companyId,
    imageId,
    threadId,
    teamId,
  }: GetLumiaThreadImageLinkRequest) => {
    const res = await this.httpClient.get<GetLumiaThreadImageLinkResponse>(
      `/harmonia-fh/company/${companyId}/thread/${threadId}/image/${imageId}/signedUrl`,
      { 
        isAuthorized: true,
        params: teamId ? { teamId } : undefined,
      }
    );
    return res;
  };
}

export enum StorageKeys {
  ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY',
  REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY',
  SELECTED_LANGUAGE_KEY = 'SELECTED_LANGUAGE_KEY',
}

export interface IStorageProvider {
  readonly getItem: (key: StorageKeys) => Promise<string | null>;
  readonly setItem: (key: StorageKeys, value: string) => Promise<void>;
  readonly removeItem: (key: StorageKeys) => Promise<void>;
}

export class NotImplementedStorageProvider implements IStorageProvider {
  public readonly getItem = () => {
    throw new Error('Not implemented');
  };
  public readonly setItem = () => {
    throw new Error('Not implemented');
  };
  public readonly removeItem = () => {
    throw new Error('Not implemented');
  };
}

export default {
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  pageTitles: {
    authentication: 'Authentication',
    home: 'Home',
  },
  authentication: {
    title: 'Hello, sign in to start!',
    description:
      'Join us and start keeping track of your daily mood, or use it with your work team!',
    email: 'Email',
    acceptTerms1: 'I declare to accept ',
    acceptTerms2: ' and ',
    acceptTerms3: ' of Qomprendo',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    emailError: 'The entered email is not valid',
    passwordlessExplanation:
      'We will send you a code to verify your identity, enter it and you will be in!',
    cta: 'Sign in',
  },
  authCode: {
    title: 'Enter the code',
    description: 'Enter the code that we sent to %{email}.',
    resendCode: 'Resend the code',
    codePlaceholder: 'Code',
    confirm: 'Confirm',
  },
  noCompany: {
    title: 'Here you are, you are in!',
    description:
      "We're happy to see you here! At the moment you are not the administrator of a company, do you want to create one? If your company uses Qomprendo, download our app!",
    cta: 'Register a company',
    downloadTheApp1: 'Are you part of a company that uses Qomprendo?',
    downloadTheApp2: 'Download the app!',
    or: 'Or',
    signOut: 'Sign out',
  },
  createCompany: {
    title: 'Register your company',
    description1:
      'Registering your company is really simple! Enter its name! After registering it you will get',
    description2: '30 days free trial!',
    namePlaceholder: "Company's name",
    companyNameError: 'Company name is required',
    code: 'Secret code',
    codeRequiredError: 'Secret code is required',
    cta: 'Register',
  },
  dashboard: {
    home: {
      link: 'Home',
      title: 'Home',
      description:
        'In this section you can get an overview of how your company is doing.',
      subtitle: 'Performance over the last 15 days',
      cardEngagement: 'Engagement',
      cardAvgVoto: 'Average Rating',
      cardBestArea: 'Best Area',
      cardWorstArea: 'Area to Improve',
      cardMostUsed: 'Most Used Area',
      tabMonth: 'Monthly Recap',
      tabAlerts: 'Alert History',
      chipUsers: 'Users',
      chipTeams: 'Teams',
      chipAnonymous: 'Anonymous users',
      email: 'Email',
      username: 'Username',
      createdAt: 'Created at',
      stressLevel: 'Stress Level',
      teamName: 'Team Name',
      teamMembers: 'Team Members',
      noData: 'No data to display',
    },
    manageUser: {
      title: 'Page of %{username}',
      userInfo: 'Member information',
      username: 'Username',
      email: 'Email',
      anonymousVote: 'Anonymous vote',
      yes: 'Yes',
      no: 'No',
      details: 'User details',
      statistics: 'Statistics',
      actionsHistory: 'Action history',
      selectDateRange: 'Select range',
      stressLevel: 'Resignation risk: %{resignRisk}%',
      teams: "User's teams",
    },
    manageUsers: {
      description:
        'In this section, you can manage the users of your company, add new ones and manage their roles.',
      title: 'Manage users',
      link: 'Manage users',
      searchPlaceholder: 'At least 3 characters..',
      addUser: 'Add',
      email: 'Email',
      username: 'Username',
      role: 'Role',
      admin: 'administrator',
      user: 'user',
      deleteModal: {
        title: 'Remove user',
        description: 'Why are you removing this user from the company?',
        voluntaryResignation: 'Voluntary resignation',
        expiredContract: 'Expired contract',
        layoff: 'Layoff',
        other: 'Other',
        explanation:
          'We use the answers to this question to improve the accuracy of burnout and layoff warnings.',
        remove: 'Remove',
        cancel: 'Cancel',
      },
      promoteToUserModal: {
        title: 'Confirm the promotion?',
        description:
          'Are you sure you want this user to have the "user" role within the company?',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      promoteToAdminModal: {
        title: 'Confirm the promotion?',
        description:
          'Are you sure you want this user to have the "administrator" role within the company?',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      addUserModal: {
        title: 'Add a user to the company',
        description:
          "Enter the user's email who you want to add to the company. The user, if not already registered, will automatically be registered and added to the company. Remember to communicate it to them!",
        add: 'Add',
        emailPlaceholder: 'Email',
        emailError: 'This email is not valid',
        cancel: 'Cancel',
        hiringDate: 'Hiring date',
        hiringDateDescription: 'Enter the date on which the user was hired.',
        hiringDateRequiredError: 'The hiring date is required',
        hiringDateFutureError: 'The hiring date cannot be in the future',
      },
    },
    manageTeams: {
      link: 'Manage teams',
      title: 'Manage teams',
      description:
        'In this section, you can manage the teams of your company, create new ones and modify existing teams.',
      teamName: 'Name',
      yes: 'Yes',
      no: 'No',
      anonymousFeedback: 'Anonymous feedback',
      active: 'Active',
      archived: 'Archived',
      workingDays: 'Days',
      teamStatus: 'Status',
      edit: 'Edit',
      archive: 'Archive',
      reactivate: 'Reactivate',
      archiveModal: {
        titleReactivate: 'Confirm reactivation?',
        titleArchive: 'Confirm archiving?',
        descriptionArchive:
          'By archiving this team on the company, its members will no longer be able to express their feedback about it.',
        descriptionReactivate:
          'By reactivating this team on the company, its members can once again express their feedback on this team.',
        cancel: 'Cancel',
        archive: 'Archive',
        reactivate: 'Reactivate',
      },
      addTeamModal: {
        title: 'Add team',
        description:
          'Complete all the steps to add a new team to your company.',
        name: {
          title: 'Give it a name',
          description:
            'Choose a descriptive name so it is easy for everyone to understand what it represents.',
          nameError: 'The name is mandatory and a maximum of 20 characters',
          namePlaceholder: 'Team name',
          continue: 'Continue',
        },
        anonymousFeedback: {
          title: 'Do you want the feedback in this team to be anonymous?',
          description:
            'People can feel exposed if feedback is not anonymous, but on the other hand a named feedback has more value. Remember that you can change this setting whenever you want',
          anonymousFeedback:
            'Yes, I want the feedback to be anonymous for this team',
          next: 'Next',
          back: 'Back',
        },
        inviteMembers: {
          title: 'Invite someone to the team!',
          description:
            'Invite the users who should be part of this team by entering their emails, the users must already be part of the company in order to join.',
          emailPlaceholder: 'User email',
          finish: 'Finish',
          back: 'Back',
        },
      },
    },
    actions: {
      menuLabel: 'Actions',
      title: 'Actions and initiatives',
      description:
        'In this section, you will find the actions and initiatives that have been taken and that impact the entire company',
    },
    manageTeam: {
      title: 'Manage %{teamName}',
      userTab: 'Members',
      settingsTab: 'Settings',
      addTeamLeaderModal: {
        title: 'Add Team Leader',
        description: 'Search and select users to add as team leaders.',
        searchPlaceholder: 'Search by email',
        add: 'Add',
        cancel: 'Cancel',
      },
      chartTab: 'Statistics',
      actionsTab: 'Actions History',
      teamAdmins: {
        title: 'Team Administrators',
        description: 'Manage the administrators of this team.',
        removeAdmin: 'Remove admin',
        loading: 'Loading administrators...',
        noData: 'No administrators found',
      },
      description:
        'In this section you can manage all the options related to this team and the users assigned to it.',
      username: 'Username',
      promote: 'Promote',
      remove: 'Remove',
      customNotifications: 'Custom Notifications',
      email: 'Email',
      role: 'User',
      user: 'User',
      admin: 'Administrator',
      anonymousFeedbackCard: {
        title: 'Do you want the feedback to be anonymous for this team?',
        description:
          "People can feel exposed if the feedback is not anonymous, but on the other hand, non-anonymous feedback has more value. It's your choice!",
      },
      notificationSection: {
        title: 'Notifications and working days',
        description:
          'Customize when to send notifications to team members and their default working days.',
        notificationAt: 'Notify at:',
      },
      addTeamMemberModal: {
        title: 'Add user to the team',
        description:
          "You're about to add a new user to the team, start typing their email and click on the correct suggestion, when it appears, to enable the addition. Remember, you can only add a user if they already belong to your company.",
        emailPlaceholder: 'User email',
        add: 'Add',
        cancel: 'Cancel',
      },
      removeMemberModal: {
        title: 'Confirm removal?',
        description: 'Are you sure you want to remove this user from the team?',
        remove: 'Remove',
        cancel: 'Cancel',
      },
      promoteMemberModal: {
        title: 'Confirm promotion?',
        description:
          'Do you confirm the promotion of this member to %{role} of the team?',
        admin: 'administrator',
        user: 'user',
        promote: 'Promote',
        cancel: 'Cancel',
      },
      customNotificationModal: {
        title: 'Custom Notifications',
        notificationEnabled: 'Enable notifications for this user',
        customNotifications: 'Customize notifications for this user.',
        description:
          "You're about to modify the working days and the time when notifications are sent to this user.",
        at: 'at',
        cancel: 'Cancel',
        edit: 'Edit',
      },
      updateTeamNameModal: {
        title: 'Change name',
        description:
          "You're about to change the name of this team. Use a simple and explanatory name, so that all team members can understand it at first glance.",
        inputPlaceholder: 'New name',
        save: 'Save',
        cancel: 'Cancel',
      },
    },
    manageCompany: {
      title: 'Manage company',
      description:
        'At the moment, the only data of your company is the name, in the future, in this section, billing information and the chosen plan will also be present.',
      companyName: 'Company name',
      edit: 'Edit',
      info: 'Information',
      editNameModal: {
        title: 'Edit company',
        description:
          'In this section you are going to modify your company data.',
        edit: 'Edit',
        cancel: 'Cancel',
        companyNamePlaceholder: 'Company name',
      },
    },
    analytics: {
      link: 'Analytics',
      title: 'Analytics',
      description:
        'In this section you can analyze the mood trend in your company, in its teams and for each person that composes it.',
      newChart: 'Do you want to create a new chart?',
      createNew: 'Create new',
      filteredBy: 'Filtered by:',
      wholeCompany: 'Whole company',
      feedbackDistribution: 'Feedback distribution',
      veryGood: 'Very Good',
      good: 'Good',
      bad: 'Bad',
      veryBad: 'Very Bad',
      forget: 'Forgot',
      avarageValue: 'Average score',
      choosenPeriod: 'on chosen period',
      noData: 'No data on chosen period',
      averageFeedback: 'Average rating',
      date: 'Date',
      noDataUserDescription:
        "It's possible that you can't see these data because the user has only expressed anonymous feedback",
      noDataAverage1: 'There are not enough data',
      noDataAverage2: 'to calculate the average score',
      dateRange: 'From %{startDate} to %{endDate}',
      positive: 'Positive',
      negative: 'Negative',
      neutral: 'Neutral',
      mixed: 'Mixed',
      sentimentTitle: 'Sentiment from comments',
      positiveCategories: 'Positive aspects',
      feedbackCategories: 'Analysis of the areas',
      badCategories: 'Aspects to improve',
      noSentimentComments:
        'There are not enough comments to calculate the predominant sentiment',
      feedbackSection: 'Feedback',
      areasSection: 'Areas',
      commentsSection: 'Comments',
      stressTitle: 'Stress in comments',
      stressed: 'Stressed',
      notStressed: 'Not stressed',
      commentsNumber: 'Comments number',
      number: 'Number',
      comments: 'Comments',
      emotionsTitle: 'Emotion in comments',
      period: 'Period:',
      emotions: {
        anger: 'Anger',
        fear: 'Fear',
        joy: 'Joy',
        sadness: 'Sadness',
        disgust: 'Disgust',
      },
      feedbackDetailsModal: {
        title: 'Feedback of ',
        titleCategory: 'Feedback from %{startDate} to %{endDate} with vote: ',
        titleMultiDate: 'Feedback from %{startDate} to %{endDate}',
        anonymous: 'anonymous',
        email: 'Email',
        vote: 'Vote',
        comment: 'Comment',
        selectedAreas: 'Selected areas',
        areas: 'Areas that influenced',
        named: 'Named',
        anonymousFilter: 'Anonymous',
      },
      additionalData: {
        title: 'General Information',
        sentimentFromComment: 'Sentiment from comments:',
        basedOn: 'based on',
        comments: 'comments',
        averageVote: 'Average vote (1-4 higher is better):',
        anonymousPercentage: 'Anonymous feedback: ',
      },
      addChart: {
        title: 'Add chart',
        description: 'Complete all the steps to create a new chart.',
        chooseEntity: {
          title: 'What do you want to filter for?',
          company: 'Whole company',
          team: 'Team',
          user: 'User',
          next: 'Next',
          cancel: 'Cancel',
        },
        chooseTeamUser: {
          whichTeam: 'Which team?',
          chooseTeam: 'Choose a team...',
          description:
            'Start typing and select the correct value from the autocomplete',
          whichUser: 'Which user?',
          userEmailPlaceholder: 'User email',
          next: 'Next',
          back: 'Back',
        },
        chooseDates: {
          title: 'What dates do you want to filter for?',
          description: 'You can choose a maximum time interval of 3 months.',
          back: 'Back',
          finish: 'Create',
        },
      },
    },
    leaderboard: {
      link: 'Gamification',
      title: 'Gamification',
      description:
        'In this section you can see the leaderboard, of this month, of the top 100 most active people in your company.',
      positionColumn: 'Pos.',
      emailColumn: 'Email',
      usernameColumn: 'Username',
      pointsColumn: 'Points',
      leaderboardTab: 'Leaderboard',
      challengeTab: 'Monthly challenge',
      gamification: {
        results: 'Results so fare',
        current: "This month's challenge",
        history: 'History',
        noChallenge: 'No challenge in progress',
        loading: 'Loading...',
        noData: 'No data to display',
        daysLeft: 'Days left:',
        prizes: 'Prizes',
        unlockAllPrizes: 'Your company has unlocked all the prizes!',
        quantity: 'Quantity',
        nextPrice: 'Next prize: %{currentPoints} / %{targetPoints}',
        noHistory: 'No history to display',
        historySection: {
          allResults: 'All obtained results',
          previousChallenges: 'Past competitions',
          range: 'Period',
          prize: 'Prize',
          quantity: 'Quantity',
        },
      },
      selectMonth: 'Select a month:',
    },
    lumia: {
      link: 'Ask to AI',
      title: 'Lumia',
      description:
        'On this page you can do data analysis and study strategies to solve possible problems simply by asking what you are interested in.',
      conversations: 'Conversations',
      newConversations: 'New conversations',
      connectionError:
        'There is a problem with the connection, try to refresh the page',
    },
    changeCompany: 'Change company',
  },
  myCompanies: {
    title: 'Companies you administer',
    description:
      'These are the companies where you have an administrator role, choose one to start managing it!',
  },
  myTeams: {
    title: 'Teams you administer',
    description:
      'These are the teams where you have an administrator role, choose one to start managing it!',
  },
  profile: {
    username: 'Username',
    email: 'Email',
    changeProfileImage: 'Change profile image',
    signOut: 'Logout',
    requestDelete: 'Request deletion',
    accountDelete: {
      subject: 'Account deletion - %{userId}',
      body: `I request the deletion of my Qomprendo account that has the following data:
  -  Id: %{userId}
  -  Email: %{userEmail}
  -  Username: %{username}
      `,
    },
  },
  editProfile: {
    title: 'Edit profile',
    description:
      'In this section you can edit your profile, at the moment only the username can be modified.',
    usernamePlaceholder: 'Username',
    usernameError: 'The username must contain at least 3 characters',
    save: 'Save profile',
  },
  language: {
    title: 'Selected language',
    change: 'Change language',
    select: 'Select the language you want to use in the application',
    languages: {
      en: 'English',
      it: 'Italiano',
      de: 'Deutsch'
    },
    save: 'Save',
    cancel: 'Cancel'
  },
  shared: {
    loading: 'Loading...',
    noData: 'No data available',
    functionLocked: {
      title: 'Function locked',
      description:
        'This function is not available in your compnay, contact us to add this feature.',
    },
    companyActionsTable: {
      implementationDate: 'Implementation date',
      title: 'Title',
      actionLevel: 'Action subject',
      actionType: 'Type',
    },
    companyActionLevel: {
      company: 'Company',
      team: 'Team',
      user: 'User',
    },
    companyActionType: {
      oneToOne: '1 on 1',
      action: 'Action/Initiative',
    },
    createCompanyActionModal: {
      title: '%{action} action %{level}',
      add: 'Add',
      edit: 'Edit',
      view: 'View',
      userTypeAction: 'Type',
      actionType: 'Action/Initiative',
      oneOnOneType: '1 on 1',
      actionDescription:
        "You're about to add an action whose subject is %{actionLevel}.",
      user: 'user',
      team: 'team',
      company: 'company',
      userLevel: 'this user',
      teamLevel: 'this team',
      companyLevel: 'the company',
      headingPlaceholder: 'Title',
      descriptionPlaceholder: 'Description',
      implementationDatePlaceholder: 'Implementation date',
      save: 'Save',
      startEdit: 'Start edit',
      cancel: 'Cancel',
      formErrors: {
        titleRequired: 'The title is a required field',
        titleTooLong: 'The title cannot exceed 40 characters',
        descriptionRequired: 'The description is a required field',
        descriptionTooLong: 'The description cannot exceed 1500 characters',
        implementationDateReq: 'The implementation date is a required field',
      },
    },
    deleteCompanyActionModal: {
      title: 'Remove action',
      description:
        'Are you sure you want to remove this action? This intervention is not reversible.',
      cancel: 'Cancel',
      remove: 'Remove',
    },
    companyArea: {
      privatelife: 'Private life',
      worklifebalance: 'Work-life balance',
      workload: 'Workload',
      coworkersrelation: 'Coworkers relation',
      managementrelation: 'Management relation',
      taskperformed: 'Task performed',
      unknown: 'Unknown',
    },
    areaRisk: {
      tooltipLabel: 'Number of votes',
    },
  },
  teamDashboard: {
    selectedTeam: 'Selected team',
    statistics: {
      link: 'Statistics',
      title: 'Statistics',
      description:
        "On this page you can get a report of the team's statistics regarding mood and areas of interest.",
    },
    actionHistory: {
      link: 'Actions history',
      title: 'Actions History',
      description:
        'This page contains the history of actions or events that occurred on the selected team.',
    },
    alerts: {
      link: 'Alerts',
      title: 'Alerts',
      description:
        'This page contains alerts that have been emitted on this team, users on this team, and anonymous users.',
    },
    settings: {
      link: 'Settings',
      title: 'Settings',
      description:
        'On this page you can manage settings that affect the team, such as working days and notifications.',
    },
    members: {
      link: 'Members',
      title: 'Members',
      description:
        'On this page are the members of the selected team, from here you can manage them and manage their attributes',
    },
    lumia: {
      link: 'Ask to AI',
      title: 'Lumia',
      description:
        'On this page you can do data analysis and study strategies to solve possible problems simply by asking what you are interested in.',
    },
  },
};

import { useCallback, useEffect, useRef } from 'react';
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import {
  addFullPageLoader,
  removeFullPageLoader,
} from '../../redux/general/general.slice';

export const InvalidationTag = {
  Company: 'company',
  CompanyAction: 'company-action',
  CompanyUser: 'company-user',
  CompanyUsers: 'company-users',
  CompanyUsersSearch: 'company-users-search',
  CompanyTeams: 'company-teams',
  TeamMembers: 'team-members',
  TeamAdmins: 'team-admins',
  Leaderboard: 'leaderboard',
  MyUser: 'my-user',
  Feedbacks: 'feedbacks',
  Alerts: 'alerts',
  FeedbacksStats: 'feedbacks-stats',
  Challenge: 'challenge',
  Achievements: 'achievements',
  LumiaThreads: 'lumia-threads',
  LumiaThreadsMessages: 'lumia-thread-messages',
};

export enum LoaderType {
  FullPage = 'full-page',
  Contextual = 'contextual',
  None = 'none',
}

type UseQuerySecondParam<T, S extends QueryKey, E = unknown> =
  | Omit<UseQueryOptions<T, E, T, S>, 'queryKey' | 'queryFn'>
  | undefined;

export const useAppQuery = <T, S extends QueryKey, E = unknown>(
  key: S,
  fetch: QueryFunction<T, S>,
  configs?: UseQuerySecondParam<T, S, E>,
  loader: LoaderType = LoaderType.FullPage
): UseQueryResult<T, E> => {
  const dispatch = useDispatch();
  const queryObj = useQuery({
    ...configs,
    queryKey: key,
    queryFn: fetch,
  });

  const loaderRef = useRef<string | null>(null);

  useEffect(() => {
    if (loader === LoaderType.FullPage) {
      if (loaderRef.current === null && queryObj.fetchStatus === 'fetching') {
        const loaderId = v4();
        loaderRef.current = loaderId;
        dispatch(addFullPageLoader(loaderId));
      } else if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
        loaderRef.current = null;
      }
    }
  }, [dispatch, loader, queryObj.fetchStatus]);

  useEffect(() => {
    return () => {
      if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
      }
    };
  }, [dispatch]);

  return queryObj;
};

type UseMutationSecondParam<T, P> =
  | Omit<UseMutationOptions<T, unknown, P, unknown>, 'mutationFn'>
  | undefined;

export const useAppMutation = <T, P>(
  fetch: MutationFunction<T, P>,
  configs?: UseMutationSecondParam<T, P>,
  loader: LoaderType = LoaderType.FullPage
): UseMutationResult<T, unknown, P, unknown> => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    ...configs,
    mutationFn: fetch,
  });

  const loaderRef = useRef<string | null>(null);

  useEffect(() => {
    if (loader === LoaderType.FullPage) {
      if (loaderRef.current === null && mutation.status === 'pending') {
        const loaderId = v4();
        loaderRef.current = loaderId;
        dispatch(addFullPageLoader(loaderId));
      } else if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
        loaderRef.current = null;
      }
    }
  }, [dispatch, loader, mutation.status]);

  useEffect(() => {
    return () => {
      if (loaderRef.current) {
        dispatch(removeFullPageLoader(loaderRef.current));
      }
    };
  }, [dispatch]);

  return mutation;
};

export const useClearCache = () => {
  const queryClient = useQueryClient();

  const clearCache = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  return clearCache;
};

import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import LeaderboardPage from '../pages/leaderboard/leaderboard.page';
import RouteAuthorizer, {
  BASE_ADMIN_ROLE_REDIRECTS,
  BASE_TEAM_LEAD_ROLE_REDIRECTS,
  BASE_USER_ROLE_REDIRECTS,
} from './components/AuthenticationRoute';
import {
  AppRoutes,
  DashboardRoutes,
  ManageTeamRoutes,
  ManageUsersRoutes,
  TeamDashboardRoutes,
} from './routes';

const CreateCompanyPage = React.lazy(
  () => import('../pages/createCompany/createCompany.page')
);
const NoCompanyPage = React.lazy(
  () => import('../pages/noCompany/noCompany.page')
);
const DashboardPage = React.lazy(
  () => import('../pages/dashboard/dashboard.page')
);
const HomePage = React.lazy(() => import('../pages/home/home.page'));
const ManageUsersPage = React.lazy(
  () => import('../pages/manageUsers/manageUsers.page')
);
const ManageUserPage = React.lazy(
  () => import('../pages/manageUser/manageUser.page')
);
const ManageTeamsPage = React.lazy(
  () => import('../pages/manageTeams/manageTeams.page')
);
const ManageTeamPage = React.lazy(
  () => import('../pages/manageTeam/manageTeam.page')
);
const MyCompaniesPage = React.lazy(
  () => import('../pages/myCompanies/myCompanies.page')
);
const AnalyticsPage = React.lazy(
  () => import('../pages/analytics/analytics.page')
);
const ManageCompanyPage = React.lazy(
  () => import('../pages/manageCompany/manageCompany.page')
);
const CompanyActionPage = React.lazy(
  () => import('../pages/companyActions/companyActions.page')
);
const MyTeamsPage = React.lazy(() => import('../pages/myTeams/myTeams.page'));
const TeamDashboardPage = React.lazy(
  () => import('../pages/teamDashboard/teamDashboard.page')
);
const TeamActionsHistoryPage = React.lazy(
  () => import('../pages/teamActionsHistory/teamActionsHistory.page')
);
const TeamAlertsPage = React.lazy(
  () => import('../pages/teamAlerts/teamAlerts.page')
);
const TeamMembersPage = React.lazy(
  () => import('../pages/teamMembers/teamMembers.page')
);
const TeamSettingsPage = React.lazy(
  () => import('../pages/teamSettings/teamSettings.page')
);
const TeamStatisticsPage = React.lazy(
  () => import('../pages/teamStatistics/teamStatistics.page')
);
const TeamLumiaPage = React.lazy(
  () => import('../pages/teamLumia/teamLumia.page')
);
const LumiaPage = React.lazy(() => import('../pages/lumia/lumia.page'));

const dashboardRoutes: RouteObject[] = [
  {
    path: AppRoutes.Base,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectRules={BASE_ADMIN_ROLE_REDIRECTS}
      >
        <Navigate to={AppRoutes.MyCompanies} replace />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.NoCompany,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.User]}
        redirectRules={BASE_USER_ROLE_REDIRECTS}
      >
        <NoCompanyPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.CreateCompany,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.User]}
        redirectRules={BASE_USER_ROLE_REDIRECTS}
      >
        <CreateCompanyPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.MyCompanies,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectRules={BASE_ADMIN_ROLE_REDIRECTS}
      >
        <MyCompaniesPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.MyTeams,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.TeamLead]}
        redirectRules={BASE_TEAM_LEAD_ROLE_REDIRECTS}
      >
        <MyTeamsPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.TeamDashboard,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.TeamLead]}
        redirectRules={BASE_TEAM_LEAD_ROLE_REDIRECTS}
      >
        <TeamDashboardPage />
      </RouteAuthorizer>
    ),
    children: [
      {
        path: `${TeamDashboardRoutes.ActionsHistory}`,
        element: <TeamActionsHistoryPage />,
      },
      {
        path: `${TeamDashboardRoutes.Alerts}`,
        element: <TeamAlertsPage />,
      },
      {
        path: `${TeamDashboardRoutes.Members}`,
        element: <TeamMembersPage />,
      },
      {
        path: `${TeamDashboardRoutes.Settings}`,
        element: <TeamSettingsPage />,
      },
      {
        path: `${TeamDashboardRoutes.Statistics}`,
        element: <TeamStatisticsPage />,
      },
      {
        path: `${TeamDashboardRoutes.Lumia}`,
        element: <TeamLumiaPage />,
      },
      {
        path: '',
        element: <Navigate to={TeamDashboardRoutes.Statistics} replace />,
      },
    ],
  },
  {
    path: AppRoutes.Dashboard,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectRules={BASE_ADMIN_ROLE_REDIRECTS}
      >
        <DashboardPage />
      </RouteAuthorizer>
    ),
    children: [
      {
        path: `${DashboardRoutes.Home}`,
        element: <HomePage />,
      },
      {
        path: `${DashboardRoutes.ManageUsers}`,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ManageUsersPage />,
          },
          {
            path: `${ManageUsersRoutes.ManageUser}`,
            element: <ManageUserPage />,
          },
        ],
      },
      {
        path: `${DashboardRoutes.ManageTeams}`,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ManageTeamsPage />,
          },
          {
            path: ManageTeamRoutes.ManageTeam,
            element: <ManageTeamPage />,
          },
        ],
      },
      {
        path: `${DashboardRoutes.Analytics}`,
        element: <AnalyticsPage />,
      },
      {
        path: `${DashboardRoutes.CompanyActions}`,
        element: <CompanyActionPage />,
      },
      {
        path: `${DashboardRoutes.ManageCompany}`,
        element: <ManageCompanyPage />,
      },
      {
        path: `${DashboardRoutes.Leaderboard}`,
        element: <LeaderboardPage />,
      },
      {
        path: `${DashboardRoutes.Challenge}`,
        element: <LeaderboardPage initialTab="challenge" />,
      },
      {
        path: `${DashboardRoutes.Lumia}`,
        element: <LumiaPage />,
      },
      {
        path: '',
        element: <Navigate to={DashboardRoutes.Home} replace />,
      },
    ],
  },
];

export default dashboardRoutes;

export enum ManageUsersRoutes {
  ManageUser = ':userId',
}

export enum ManageTeamRoutes {
  ManageTeam = ':teamId',
}

export enum DashboardRoutes {
  ManageUsers = 'manage-users',
  ManageTeams = 'manage-teams',
  ManageCompany = 'manage-company',
  ManageTeam = 'manage-teams/:teamId',
  Analytics = 'analytics',
  Leaderboard = 'leaderboard',
  Challenge = 'challenge',
  CompanyActions = 'actions',
  Lumia = 'lumia',
  Home = 'home',
}

export enum TeamDashboardRoutes {
  Statistics = 'statistics',
  ActionsHistory = 'actions-history',
  Alerts = 'alerts',
  Settings = 'settings',
  Members = 'members',
  Lumia = 'lumia',
}

export enum AppRoutes {
  Base = '/',
  Autenthication = '/auth',
  AuthCode = '/auth/code',
  NoCompany = '/no-company',
  CreateCompany = '/company/new',
  MyCompanies = '/my-companies',
  MyTeams = '/my-teams',
  Profile = '/profile',
  EditProfile = '/profile/edit',

  Dashboard = '/dashboard/:companyId',
  DashboardHome = '/dashboard/:companyId/home',
  DashboardManageUser = '/dashboard/:companyId/manage-users/:userId',
  DashboardManageUsers = '/dashboard/:companyId/manage-users',
  DashboardManageTeams = '/dashboard/:companyId/manage-teams',
  DashboardAnalytics = '/dashboard/:companyId/analytics',
  DashboardManageCompany = '/dashboard/:companyId/manage-company',
  DashboardManageTeam = '/dashboard/:companyId/manage-teams/:teamId',
  DashboardLeaderboard = '/dashboard/:companyId/leaderboard',
  DashboardCompanyActions = '/dashboard/:companyId/actions',
  DashboardLumia = '/dashboard/:companyId/lumia',

  TeamDashboard = '/team-dashboard/:teamId',
  TeamDashboardStatistics = '/team-dashboard/:teamId/statistics',
  TeamDashboardActionsHistory = '/team-dashboard/:teamId/actions-history',
  TeamDashboardAlerts = '/team-dashboard/:teamId/alerts',
  TeamDashboardSettings = '/team-dashboard/:teamId/settings',
  TeamDashboardMembers = '/team-dashboard/:teamId/members',
  TeamDashboardMemberDetail = '/team-dashboard/:teamId/members/:userId',
  TeamDashboardLumia = '/team-dashboard/:teamId/lumia',
}

export const createDashboardUserLink = (companyId: string, userId: string) => {
  return AppRoutes.DashboardManageUser.replace(':companyId', companyId).replace(
    ':userId',
    userId
  );
};

export const createTeamDashboardLink = (teamId: string) => {
  return AppRoutes.TeamDashboard.replace(':teamId', teamId);
};

export const createDashboardTeamLink = (companyId: string, teamId: string) => {
  return AppRoutes.DashboardManageTeam.replace(':companyId', companyId).replace(
    ':teamId',
    teamId
  );
};

export default {
  days: {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  },
  pageTitles: {
    authentication: 'Authentifizierung',
    home: 'Startseite',
  },
  authentication: {
    title: 'Hallo, melden Sie sich an, um zu beginnen!',
    description:
      'Treten Sie uns bei und beginnen Sie, Ihre tägliche Stimmung zu verfolgen oder nutzen Sie es mit Ihrem Arbeitsteam!',
    email: 'E-Mail',
    acceptTerms1: 'Ich erkläre mich einverstanden mit den ',
    acceptTerms2: ' und der ',
    acceptTerms3: ' von Qomprendo',
    termsAndConditions: 'Allgemeinen Geschäftsbedingungen',
    privacyPolicy: 'Datenschutzerklärung',
    emailError: 'Die eingegebene E-Mail ist nicht gültig',
    passwordlessExplanation:
      'Wir senden Ihnen einen Code zur Identitätsprüfung. Geben Sie ihn ein und Sie sind dabei!',
    cta: 'Anmelden',
  },
  authCode: {
    title: 'Code eingeben',
    description: 'Geben Sie den Code ein, den wir an %{email} gesendet haben.',
    resendCode: 'Code erneut senden',
    codePlaceholder: 'Code',
    confirm: 'Bestätigen',
  },
  noCompany: {
    title: 'Da sind Sie, Sie sind drin!',
    description:
      'Wir freuen uns, Sie hier zu sehen! Momentan sind Sie kein Administrator eines Unternehmens. Möchten Sie eines erstellen? Wenn Ihr Unternehmen Qomprendo nutzt, laden Sie unsere App herunter!',
    cta: 'Unternehmen registrieren',
    downloadTheApp1: 'Sind Sie Teil eines Unternehmens, das Qomprendo nutzt?',
    downloadTheApp2: 'Laden Sie die App herunter!',
    or: 'Oder',
    signOut: 'Abmelden',
  },
  createCompany: {
    title: 'Registrieren Sie Ihr Unternehmen',
    description1:
      'Die Registrierung Ihres Unternehmens ist wirklich einfach! Geben Sie seinen Namen ein! Nach der Registrierung erhalten Sie',
    description2: '30 Tage kostenlose Testversion!',
    namePlaceholder: 'Unternehmensname',
    companyNameError: 'Unternehmensname ist erforderlich',
    code: 'Geheimer Code',
    codeRequiredError: 'Geheimer Code ist erforderlich',
    cta: 'Registrieren',
  },
  dashboard: {
    home: {
      link: 'Startseite',
      title: 'Startseite',
      description:
        'In diesem Bereich erhalten Sie einen Überblick darüber, wie es Ihrem Unternehmen geht.',
      subtitle: 'Leistung der letzten 15 Tage',
      cardEngagement: 'Engagement',
      cardAvgVoto: 'Durchschnittliche Bewertung',
      cardBestArea: 'Bester Bereich',
      cardWorstArea: 'Verbesserungsbedarf',
      cardMostUsed: 'Meist genutzter Bereich',
      tabMonth: 'Monatliche Zusammenfassung',
      tabAlerts: 'Warnungsverlauf',
      chipUsers: 'Benutzer',
      chipTeams: 'Teams',
      chipAnonymous: 'Anonyme Benutzer',
      email: 'E-Mail',
      username: 'Benutzername',
      createdAt: 'Erstellt am',
      stressLevel: 'Stresslevel',
      teamName: 'Teamname',
      teamMembers: 'Teammitglieder',
      noData: 'Keine Daten zum Anzeigen',
    },
    manageUser: {
      title: 'Seite von %{username}',
      userInfo: 'Mitgliederinformationen',
      username: 'Benutzername',
      email: 'E-Mail',
      anonymousVote: 'Anonyme Abstimmung',
      yes: 'Ja',
      no: 'Nein',
      details: 'Benutzerdetails',
      statistics: 'Statistiken',
      actionsHistory: 'Aktionsverlauf',
      selectDateRange: 'Zeitraum auswählen',
      stressLevel: 'Kündigungsrisiko: %{resignRisk}%',
      teams: 'Teams des Benutzers',
    },
    manageUsers: {
      description:
        'In diesem Bereich können Sie die Benutzer Ihres Unternehmens verwalten, neue hinzufügen und ihre Rollen verwalten.',
      title: 'Benutzer verwalten',
      link: 'Benutzer verwalten',
      searchPlaceholder: 'Mindestens 3 Zeichen..',
      addUser: 'Hinzufügen',
      email: 'E-Mail',
      username: 'Benutzername',
      role: 'Rolle',
      admin: 'Administrator',
      user: 'Benutzer',
      deleteModal: {
        title: 'Benutzer entfernen',
        description: 'Warum entfernen Sie diesen Benutzer aus dem Unternehmen?',
        voluntaryResignation: 'Freiwilliger Rücktritt',
        expiredContract: 'Vertrag abgelaufen',
        layoff: 'Kündigung',
        other: 'Sonstiges',
        explanation:
          'Wir verwenden die Antworten auf diese Frage, um die Genauigkeit von Burnout- und Kündigungswarnungen zu verbessern.',
        remove: 'Entfernen',
        cancel: 'Abbrechen',
      },
      promoteToUserModal: {
        title: 'Beförderung bestätigen?',
        description:
          'Sind Sie sicher, dass dieser Benutzer die Rolle "Benutzer" im Unternehmen haben soll?',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
      },
      promoteToAdminModal: {
        title: 'Beförderung bestätigen?',
        description:
          'Sind Sie sicher, dass dieser Benutzer die Rolle "Administrator" im Unternehmen haben soll?',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
      },
      addUserModal: {
        title: 'Benutzer zum Unternehmen hinzufügen',
        description:
          'Geben Sie die E-Mail des Benutzers ein, den Sie zum Unternehmen hinzufügen möchten. Der Benutzer wird, falls noch nicht registriert, automatisch registriert und dem Unternehmen hinzugefügt. Denken Sie daran, es ihm mitzuteilen!',
        add: 'Hinzufügen',
        emailPlaceholder: 'E-Mail',
        emailError: 'Diese E-Mail ist nicht gültig',
        cancel: 'Abbrechen',
        hiringDate: 'Einstellungsdatum',
        hiringDateDescription: 'Geben Sie das Datum ein, an dem der Benutzer eingestellt wurde.',
        hiringDateRequiredError: 'Das Einstellungsdatum ist erforderlich',
        hiringDateFutureError: 'Das Einstellungsdatum kann nicht in der Zukunft liegen',
      },
    },
    manageTeams: {
      link: 'Teams verwalten',
      title: 'Teams verwalten',
      description:
        'In diesem Bereich können Sie die Teams Ihres Unternehmens verwalten, neue erstellen und bestehende Teams ändern.',
      teamName: 'Name',
      yes: 'Ja',
      no: 'Nein',
      anonymousFeedback: 'Anonymes Feedback',
      active: 'Aktiv',
      archived: 'Archiviert',
      workingDays: 'Tage',
      teamStatus: 'Status',
      edit: 'Bearbeiten',
      archive: 'Archivieren',
      reactivate: 'Reaktivieren',
      archiveModal: {
        titleReactivate: 'Reaktivierung bestätigen?',
        titleArchive: 'Archivierung bestätigen?',
        descriptionArchive:
          'Durch die Archivierung dieses Teams können dessen Mitglieder kein Feedback mehr dazu abgeben.',
        descriptionReactivate:
          'Durch die Reaktivierung dieses Teams können dessen Mitglieder wieder Feedback zu diesem Team abgeben.',
        cancel: 'Abbrechen',
        archive: 'Archivieren',
        reactivate: 'Reaktivieren',
      },
      addTeamModal: {
        title: 'Team hinzufügen',
        description:
          'Führen Sie alle Schritte aus, um ein neues Team zu Ihrem Unternehmen hinzuzufügen.',
        name: {
          title: 'Geben Sie ihm einen Namen',
          description:
            'Wählen Sie einen beschreibenden Namen, damit alle leicht verstehen können, wofür es steht.',
          nameError: 'Der Name ist erforderlich und maximal 20 Zeichen lang',
          namePlaceholder: 'Teamname',
          continue: 'Weiter',
        },
        anonymousFeedback: {
          title: 'Soll das Feedback in diesem Team anonym sein?',
          description:
            'Menschen können sich exponiert fühlen, wenn Feedback nicht anonym ist, aber andererseits hat ein namentliches Feedback mehr Wert. Denken Sie daran, dass Sie diese Einstellung jederzeit ändern können',
          anonymousFeedback:
            'Ja, ich möchte, dass das Feedback für dieses Team anonym ist',
          next: 'Weiter',
          back: 'Zurück',
        },
        inviteMembers: {
          title: 'Laden Sie jemanden ins Team ein!',
          description:
            'Laden Sie die Benutzer ein, die Teil dieses Teams sein sollen, indem Sie ihre E-Mails eingeben. Die Benutzer müssen bereits Teil des Unternehmens sein.',
          emailPlaceholder: 'Benutzer-E-Mail',
          finish: 'Fertigstellen',
          back: 'Zurück',
        },
      },
    },
    actions: {
      menuLabel: 'Aktionen',
      title: 'Aktionen und Initiativen',
      description:
        'In diesem Bereich finden Sie die Aktionen und Initiativen, die ergriffen wurden und das gesamte Unternehmen betreffen',
    },
    manageTeam: {
      title: '%{teamName} verwalten',
      userTab: 'Mitglieder',
      settingsTab: 'Einstellungen',
      addTeamLeaderModal: {
        title: 'Teamleiter hinzufügen',
        description: 'Suchen und wählen Sie Benutzer als Teamleiter aus.',
        searchPlaceholder: 'Nach E-Mail suchen',
        add: 'Hinzufügen',
        cancel: 'Abbrechen',
      },
      chartTab: 'Statistiken',
      actionsTab: 'Aktionsverlauf',
      teamAdmins: {
        title: 'Team-Administratoren',
        description: 'Verwalten Sie die Administratoren dieses Teams.',
        removeAdmin: 'Admin entfernen',
        loading: 'Administratoren werden geladen...',
        noData: 'Keine Administratoren gefunden',
      },
      description:
        'In diesem Bereich können Sie alle Optionen für dieses Team und die zugewiesenen Benutzer verwalten.',
      username: 'Benutzername',
      promote: 'Befördern',
      remove: 'Entfernen',
      customNotifications: 'Benutzerdefinierte Benachrichtigungen',
      email: 'E-Mail',
      role: 'Benutzer',
      user: 'Benutzer',
      admin: 'Administrator',
      anonymousFeedbackCard: {
        title: 'Soll das Feedback für dieses Team anonym sein?',
        description:
          'Menschen können sich exponiert fühlen, wenn das Feedback nicht anonym ist, aber andererseits hat nicht-anonymes Feedback mehr Wert. Es ist Ihre Wahl!',
      },
      notificationSection: {
        title: 'Benachrichtigungen und Arbeitstage',
        description:
          'Passen Sie an, wann Teammitglieder Benachrichtigungen erhalten und ihre Standard-Arbeitstage.',
        notificationAt: 'Benachrichtigen um:',
      },
      addTeamMemberModal: {
        title: 'Benutzer zum Team hinzufügen',
        description:
          'Sie fügen einen neuen Benutzer zum Team hinzu. Beginnen Sie mit der Eingabe ihrer E-Mail-Adresse und klicken Sie auf den richtigen Vorschlag, wenn er erscheint, um das Hinzufügen zu ermöglichen. Denken Sie daran, Sie können einen Benutzer nur hinzufügen, wenn er bereits zu Ihrem Unternehmen gehört.',
        emailPlaceholder: 'Benutzer-E-Mail',
        add: 'Hinzufügen',
        cancel: 'Abbrechen',
      },
      removeMemberModal: {
        title: 'Entfernung bestätigen?',
        description: 'Sind Sie sicher, dass Sie diesen Benutzer aus dem Team entfernen möchten?',
        remove: 'Entfernen',
        cancel: 'Abbrechen',
      },
      promoteMemberModal: {
        title: 'Beförderung bestätigen?',
        description:
          'Bestätigen Sie die Beförderung dieses Mitglieds zum %{role} des Teams?',
        admin: 'Administrator',
        user: 'Benutzer',
        promote: 'Befördern',
        cancel: 'Abbrechen',
      },
      customNotificationModal: {
        title: 'Benutzerdefinierte Benachrichtigungen',
        notificationEnabled: 'Benachrichtigungen für diesen Benutzer aktivieren',
        customNotifications: 'Benachrichtigungen für diesen Benutzer anpassen.',
        description:
          'Sie ändern die Arbeitstage und die Zeit, zu der Benachrichtigungen an diesen Benutzer gesendet werden.',
        at: 'um',
        cancel: 'Abbrechen',
        edit: 'Bearbeiten',
      },
      updateTeamNameModal: {
        title: 'Namen ändern',
        description:
          'Sie ändern den Namen dieses Teams. Verwenden Sie einen einfachen und aussagekräftigen Namen, damit alle Teammitglieder ihn auf den ersten Blick verstehen können.',
        inputPlaceholder: 'Neuer Name',
        save: 'Speichern',
        cancel: 'Abbrechen',
      },
    },
    manageCompany: {
      title: 'Unternehmen verwalten',
      description:
        'Momentan sind die einzigen Daten Ihres Unternehmens der Name. In Zukunft werden in diesem Bereich auch Abrechnungsinformationen und der gewählte Plan vorhanden sein.',
      companyName: 'Unternehmensname',
      edit: 'Bearbeiten',
      info: 'Informationen',
      editNameModal: {
        title: 'Unternehmen bearbeiten',
        description:
          'In diesem Bereich werden Sie die Daten Ihres Unternehmens ändern.',
        edit: 'Bearbeiten',
        cancel: 'Abbrechen',
        companyNamePlaceholder: 'Unternehmensname',
      },
    },
    analytics: {
      link: 'Analytik',
      title: 'Analytik',
      description:
        'In diesem Bereich können Sie die Stimmungsentwicklung in Ihrem Unternehmen, seinen Teams und für jede Person analysieren.',
      newChart: 'Möchten Sie ein neues Diagramm erstellen?',
      createNew: 'Neu erstellen',
      filteredBy: 'Gefiltert nach:',
      wholeCompany: 'Gesamtes Unternehmen',
      feedbackDistribution: 'Feedback-Verteilung',
      veryGood: 'Sehr gut',
      good: 'Gut',
      bad: 'Schlecht',
      veryBad: 'Sehr schlecht',
      forget: 'Vergessen',
      avarageValue: 'Durchschnittliche Bewertung',
      choosenPeriod: 'im gewählten Zeitraum',
      noData: 'Keine Daten im gewählten Zeitraum',
      averageFeedback: 'Durchschnittliche Bewertung',
      date: 'Datum',
      noDataUserDescription:
        'Möglicherweise können Sie diese Daten nicht sehen, weil der Benutzer nur anonymes Feedback gegeben hat',
      noDataAverage1: 'Es gibt nicht genügend Daten',
      noDataAverage2: 'um die Durchschnittsbewertung zu berechnen',
      dateRange: 'Von %{startDate} bis %{endDate}',
      positive: 'Positiv',
      negative: 'Negativ',
      neutral: 'Neutral',
      mixed: 'Gemischt',
      sentimentTitle: 'Stimmung aus Kommentaren',
      positiveCategories: 'Positive Aspekte',
      feedbackCategories: 'Analyse der Bereiche',
      badCategories: 'Verbesserungsbedürftige Aspekte',
      noSentimentComments:
        'Es gibt nicht genügend Kommentare, um die vorherrschende Stimmung zu berechnen',
      feedbackSection: 'Feedback',
      areasSection: 'Bereiche',
      commentsSection: 'Kommentare',
      stressTitle: 'Stress in Kommentaren',
      stressed: 'Gestresst',
      notStressed: 'Nicht gestresst',
      commentsNumber: 'Anzahl der Kommentare',
      number: 'Anzahl',
      comments: 'Kommentare',
      emotionsTitle: 'Emotionen in Kommentaren',
      period: 'Zeitraum:',
      emotions: {
        anger: 'Wut',
        fear: 'Angst',
        joy: 'Freude',
        sadness: 'Traurigkeit',
        disgust: 'Ekel',
      },
      feedbackDetailsModal: {
        title: 'Feedback von ',
        titleCategory: 'Feedback von %{startDate} bis %{endDate} mit Bewertung: ',
        titleMultiDate: 'Feedback von %{startDate} bis %{endDate}',
        anonymous: 'anonym',
        email: 'E-Mail',
        vote: 'Bewertung',
        comment: 'Kommentar',
        selectedAreas: 'Ausgewählte Bereiche',
        areas: 'Beeinflusste Bereiche',
        named: 'Namentlich',
        anonymousFilter: 'Anonym',
      },
      additionalData: {
        title: 'Allgemeine Informationen',
        sentimentFromComment: 'Stimmung aus Kommentaren:',
        basedOn: 'basierend auf',
        comments: 'Kommentaren',
        averageVote: 'Durchschnittliche Bewertung (1-4, höher ist besser):',
        anonymousPercentage: 'Anonymes Feedback: ',
      },
      addChart: {
        title: 'Diagramm hinzufügen',
        description: 'Führen Sie alle Schritte aus, um ein neues Diagramm zu erstellen.',
        chooseEntity: {
          title: 'Wonach möchten Sie filtern?',
          company: 'Gesamtes Unternehmen',
          team: 'Team',
          user: 'Benutzer',
          next: 'Weiter',
          cancel: 'Abbrechen',
        },
        chooseTeamUser: {
          whichTeam: 'Welches Team?',
          chooseTeam: 'Team auswählen...',
          description:
            'Beginnen Sie mit der Eingabe und wählen Sie den richtigen Wert aus der Autovervollständigung',
          whichUser: 'Welcher Benutzer?',
          userEmailPlaceholder: 'Benutzer-E-Mail',
          next: 'Weiter',
          back: 'Zurück',
        },
        chooseDates: {
          title: 'Für welchen Zeitraum möchten Sie filtern?',
          description: 'Sie können einen maximalen Zeitraum von 3 Monaten wählen.',
          back: 'Zurück',
          finish: 'Erstellen',
        },
      },
    },
    leaderboard: {
      link: 'Gamifizierung',
      title: 'Gamifizierung',
      description:
        'In diesem Bereich sehen Sie die Bestenliste der 100 aktivsten Personen in Ihrem Unternehmen in diesem Monat.',
      positionColumn: 'Pos.',
      emailColumn: 'E-Mail',
      usernameColumn: 'Benutzername',
      pointsColumn: 'Punkte',
      leaderboardTab: 'Bestenliste',
      challengeTab: 'Monatliche Herausforderung',
      gamification: {
        results: 'Bisherige Ergebnisse', // Corrected from English "Results so fare"
        current: 'Aktuelle Monatsherausforderung',
        history: 'Verlauf',
        noChallenge: 'Keine laufende Herausforderung',
        loading: 'Laden...',
        noData: 'Keine Daten zum Anzeigen',
        daysLeft: 'Verbleibende Tage:',
        prizes: 'Preise',
        unlockAllPrizes: 'Ihr Unternehmen hat alle Preise freigeschaltet!',
        quantity: 'Menge',
        nextPrice: 'Nächster Preis: %{currentPoints} / %{targetPoints}',
        noHistory: 'Kein Verlauf zum Anzeigen',
        historySection: {
          allResults: 'Alle erzielten Ergebnisse',
          previousChallenges: 'Vergangene Wettbewerbe',
          range: 'Zeitraum',
          prize: 'Preis',
          quantity: 'Menge',
        },
      },
      selectMonth: 'Wählen Sie einen Monat:',
    },
    lumia: {
      link: 'KI fragen',
      title: 'Lumia',
      description:
        'Auf dieser Seite können Sie Datenanalysen durchführen und Strategien zur Lösung möglicher Probleme entwickeln, indem Sie einfach fragen, was Sie interessiert.',
      conversations: 'Gespräche',
      newConversations: 'Neue Gespräche',
      connectionError:
        'Es gibt ein Problem mit der Verbindung, versuchen Sie die Seite zu aktualisieren',
    },
    changeCompany: 'Unternehmen wechseln',
  },
  myCompanies: {
    title: 'Unternehmen, die Sie verwalten',
    description:
      'Dies sind die Unternehmen, in denen Sie eine Administratorrolle haben. Wählen Sie eines aus, um es zu verwalten!',
  },
  myTeams: {
    title: 'Teams, die Sie verwalten',
    description:
      'Dies sind die Teams, in denen Sie eine Administratorrolle haben. Wählen Sie eines aus, um es zu verwalten!',
  },
  profile: {
    username: 'Benutzername',
    email: 'E-Mail',
    changeProfileImage: 'Profilbild ändern',
    signOut: 'Abmelden',
    requestDelete: 'Löschung beantragen',
    accountDelete: {
      subject: 'Kontolöschung - %{userId}',
      body: `Ich beantrage die Löschung meines Qomprendo-Kontos mit folgenden Daten:
  -  Id: %{userId}
  -  E-Mail: %{userEmail}
  -  Benutzername: %{username}
      `,
    },
  },
  editProfile: {
    title: 'Profil bearbeiten',
    description:
      'In diesem Bereich können Sie Ihr Profil bearbeiten. Derzeit kann nur der Benutzername geändert werden.',
    usernamePlaceholder: 'Benutzername',
    usernameError: 'Der Benutzername muss mindestens 3 Zeichen enthalten',
    save: 'Profil speichern',
  },
  language: {
    title: 'Ausgewählte Sprache',
    change: 'Sprache ändern',
    select: 'Wählen Sie die Sprache aus, die Sie in der Anwendung verwenden möchten',
    languages: {
      en: 'English',
      it: 'Italiano',
      de: 'Deutsch'
    },
    save: 'Speichern',
    cancel: 'Abbrechen'
  },
  shared: {
    loading: 'Laden...',
    noData: 'Keine Daten verfügbar',
    functionLocked: {
      title: 'Funktion gesperrt',
      description:
        'Diese Funktion ist in Ihrem Unternehmen nicht verfügbar. Kontaktieren Sie uns, um diese Funktion hinzuzufügen.',
    },
    companyActionsTable: {
      implementationDate: 'Umsetzungsdatum',
      title: 'Titel',
      actionLevel: 'Aktionsbetreff',
      actionType: 'Typ',
    },
    companyActionLevel: {
      company: 'Unternehmen',
      team: 'Team',
      user: 'Benutzer',
    },
    companyActionType: {
      oneToOne: '1 zu 1',
      action: 'Aktion/Initiative',
    },
    createCompanyActionModal: {
      title: '%{action} Aktion %{level}',
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      view: 'Ansehen',
      userTypeAction: 'Typ',
      actionType: 'Aktion/Initiative',
      oneOnOneType: '1 zu 1',
      actionDescription:
        'Sie fügen eine Aktion hinzu, deren Betreff %{actionLevel} ist.',
      user: 'Benutzer',
      team: 'Team',
      company: 'Unternehmen',
      userLevel: 'dieser Benutzer',
      teamLevel: 'dieses Team',
      companyLevel: 'das Unternehmen',
      headingPlaceholder: 'Titel',
      descriptionPlaceholder: 'Beschreibung',
      implementationDatePlaceholder: 'Umsetzungsdatum',
      save: 'Speichern',
      startEdit: 'Bearbeitung beginnen',
      cancel: 'Abbrechen',
      formErrors: {
        titleRequired: 'Der Titel ist ein Pflichtfeld',
        titleTooLong: 'Der Titel darf 40 Zeichen nicht überschreiten',
        descriptionRequired: 'Die Beschreibung ist ein Pflichtfeld',
        descriptionTooLong: 'Die Beschreibung darf 1500 Zeichen nicht überschreiten',
        implementationDateReq: 'Das Umsetzungsdatum ist ein Pflichtfeld',
      },
    },
    deleteCompanyActionModal: {
      title: 'Aktion entfernen',
      description:
        'Sind Sie sicher, dass Sie diese Aktion entfernen möchten? Dieser Eingriff ist nicht rückgängig zu machen.',
      cancel: 'Abbrechen',
      remove: 'Entfernen',
    },
    companyArea: {
      privatelife: 'Privatleben',
      worklifebalance: 'Work-Life-Balance',
      workload: 'Arbeitsbelastung',
      coworkersrelation: 'Beziehung zu Kollegen',
      managementrelation: 'Beziehung zum Management',
      taskperformed: 'Ausgeführte Aufgaben',
      unknown: 'Unbekannt',
    },
    areaRisk: {
      tooltipLabel: 'Anzahl der Stimmen',
    },
  },
  teamDashboard: {
    selectedTeam: 'Ausgewähltes Team',
    statistics: {
      link: 'Statistiken',
      title: 'Statistiken',
      description:
        'Auf dieser Seite erhalten Sie einen Bericht über die Teamstatistiken bezüglich Stimmung und Interessensbereiche.',
    },
    actionHistory: {
      link: 'Aktionsverlauf',
      title: 'Aktionsverlauf',
      description:
        'Diese Seite enthält den Verlauf der Aktionen oder Ereignisse, die im ausgewählten Team stattgefunden haben.',
    },
    alerts: {
      link: 'Warnungen',
      title: 'Warnungen',
      description:
        'Diese Seite enthält Warnungen, die für dieses Team, Benutzer in diesem Team und anonyme Benutzer ausgegeben wurden.',
    },
    settings: {
      link: 'Einstellungen',
      title: 'Einstellungen',
      description:
        'Auf dieser Seite können Sie Einstellungen verwalten, die das Team betreffen, wie Arbeitstage und Benachrichtigungen.',
    },
    members: {
      link: 'Mitglieder',
      title: 'Mitglieder',
      description:
        'Auf dieser Seite befinden sich die Mitglieder des ausgewählten Teams. Von hier aus können Sie sie und ihre Attribute verwalten.',
    },
    lumia: {
      link: 'KI fragen',
      title: 'Lumia',
      description:
        'Auf dieser Seite können Sie Datenanalysen durchführen und Strategien zur Lösung möglicher Probleme entwickeln, indem Sie einfach fragen, was Sie interessiert.',
    },
  },
};
